import apiInstance from './instance';

// Function to add a new vehicle model
export const addVehicleModel = async (payload) => {
  try {
    const response = await apiInstance({
      endpoint: '/model',
      method: 'POST',
      body: payload,
    });
    return response;
  } catch (error) {
    console.error('Error adding vehicle model:', error);
    throw error;
  }
};

// Function to fetch booking details by ID
export const getBookingDetails = async (bookingId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/booking/${bookingId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching booking details:', error);
    throw error;
  }
};

// Function to create a business user
export const createBusinessUser = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/business-user-creation',
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    console.error('Error creating business user:', error);
    throw error;
  }
};

// Function to fetch models
export const fetchModels = async () => {
  try {
    const response = await apiInstance({
      endpoint: '/models/list',
      method: 'POST',
      body: {
        wheelsizeRestriction: [],
        vendorRestriction: [],
      },
    });
    return response.models;
  } catch (error) {
    throw new Error('Error fetching models: ' + error.message);
  }
};

// Function to resolve QR code
export const resolveQR = async (chargerId) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/qr/resolve',
      method: 'POST',
      body: {
        qrText: `chargepe://charge?cid=${chargerId}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error('Error resolving QR code: ' + error.message);
  }
};

// New function to fetch migration request details by request ID
export const getMigrationDetails = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/pg/migration/${requestId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching migration details:', error);
    throw error;
  }
};

// For verify migration request 
export const verifyMigration = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/pg/migration/${requestId}/verify`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error verifying migration:', error);
    return false;
  }
};

// For cleanup migration request 
export const cleanupMigration = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/pg/migration/${requestId}/cleanup`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error cleaning up migration:', error);
    return false;
  }
};

export const getAllOperators = async () => {
  const response = await fetch('https://api.chargepe.io/v1/internaluser/operator/list', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJVU1I4MTU5MDgyMTAxIiwiaWF0IjoxNzMwNDM4MjA5LCJpc3MiOiJjaGFyZ2VwZSIsInVzZXJEZXRhaWxzIjp7InVzZXJJZCI6IlVTUjgxNTkwODIxMDEiLCJuZXdVc2VyIjpmYWxzZSwiY3JlYXRlZEF0IjpudWxsLCJncm91cE5hbWUiOm51bGwsImdyb3VwVmFsdWUiOm51bGwsImF1dGhvcml0aWVzIjpbIkIyQyJdfSwiZXhwIjoxNzM5MDc4MjA5fQ.H92iGsJ0hrizx_AgBvCG1NEJ2kdK3Q3wruZbPeNFer3Imhrvs0czw8u8nXBRoqhFFfQVjKXuVAVGJDJ2aiXhjg', 
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok: ' + response.statusText);
  }

  return await response.json();
};

export const getOperatorDetails = async (operatorId) => {
  const response = await fetch(`https://api.chargepe.io/v1/internaluser/operator/${operatorId}/details`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJVU1I4MTU5MDgyMTAxIiwiaWF0IjoxNzMwNDM4MjA5LCJpc3MiOiJjaGFyZ2VwZSIsInVzZXJEZXRhaWxzIjp7InVzZXJJZCI6IlVTUjgxNTkwODIxMDEiLCJuZXdVc2VyIjpmYWxzZSwiY3JlYXRlZEF0IjpudWxsLCJncm91cE5hbWUiOm51bGwsImdyb3VwVmFsdWUiOm51bGwsImF1dGhvcml0aWVzIjpbIkIyQyJdfSwiZXhwIjoxNzM5MDc4MjA5fQ.H92iGsJ0hrizx_AgBvCG1NEJ2kdK3Q3wruZbPeNFer3Imhrvs0czw8u8nXBRoqhFFfQVjKXuVAVGJDJ2aiXhjg',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok: ' + response.statusText);
  }

  return await response.json();
};






export const fetchStoreDetails = async (storeId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching store details:', error);
    throw error;
  }
};

// Functions from InternalStoresSearch component
export const fetchStores = async (location, states, page, pageSize) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/stores/list',
      method: 'POST',
      body: {
        latLng: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        radiusMeters: 50000,
        states,
        pageRequest: {
          pageNumber: page,
          pageSize,
        },
      },
    });
    return response.storeContexts;
  } catch (error) {
    console.error('Error fetching stores:', error);
    throw error;
  }
};

export const updateStore = async (storeId, updatedStoreData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}`,
      method: 'PUT',
      body: updatedStoreData,
    });
    return response;
  } catch (error) {
    console.error('Error updating store:', error);
    throw error;
  }
};

// Functions from StoreDetailsEditor component
export const approveImage = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/approve`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error approving image:', error);
    throw error;
  }
};

export const rejectImage = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/reject`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error rejecting image:', error);
    throw error;
  }
};

export const updateStoreData = async (storeId, updatedStoreData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}`,
      method: 'PUT',
      body: updatedStoreData,
    });
    return response;
  } catch (error) {
    console.error('Error updating store data:', error);
    throw error;
  }
};



// api.jsx

export const fetchLocationSuggestions = async (query) => {
  try {
    const response = await apiInstance({
      endpoint: '/google/place/autocomplete',
      method: 'GET',
      params: { prompt: query },
    });
    return response.data.predictions;
  } catch (error) {
    console.error('Error fetching location suggestions:', error);
    throw error;
  }
};
