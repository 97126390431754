import React, { useEffect, useState } from 'react';
import { getAllOperators, getOperatorDetails } from '../api';
// import { CodeBlock } from 'react-code-blocks';
import { JsonViewer } from '@textea/json-viewer'
import { FaTimes } from 'react-icons/fa';

const Operators = () => {
  const [operators, setOperators] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({});
  const [searchType, setSearchType] = useState('#');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchType === 'all') fetchAllOperators();
  }, [searchType]);

  const fetchAllOperators = async () => {
    try {
      const data = await getAllOperators();
      setOperators(data);
    } catch (error) {
      console.error('Failed to fetch operators:', error);
      setError('Failed to load operators');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (searchType === 'all') {
      fetchAllOperators();
    } else if (searchType === 'operatorId') {
      try {
        const details = await getOperatorDetails(searchValue);
        setOperators([details]);
      } catch (error) {
        console.error('Failed to fetch operator by ID:', error);
        setError('Operator not found');
      }
    } else if (searchType === 'network') {
      try {
        const data = await getAllOperators();
        const filteredOperators = data.filter(
          (operator) => operator.network.toLowerCase() === searchValue.toLowerCase()
        );
        setOperators(filteredOperators);
      } catch (error) {
        console.error('Failed to fetch operators by network:', error);
        setError('Failed to load operators');
      }
    }
    setLoading(false);
  };

  const handleOperatorClick = async (operatorId) => {
    try {
      const details = await getOperatorDetails(operatorId);
      setSelectedOperator(details);
      setEditForm(details);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to fetch operator details:', error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };


  const handleSaveChanges = async () => {
    try {
      setSelectedOperator(editForm);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update operator details:', error);
    }
  };

  const closeModal = () => {
    setSelectedOperator(null);
  };



  useEffect(() => {
    setOperators([]);
    setSearchValue('');
  }, [searchType]);

  if (loading) return <p className="text-center">Loading...</p>;
  if (error) return <p className="text-red-500 text-center">{error}</p>;

  return (
    <div className="min-h-screen flex-col items-center p-4">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6">Operator List</h1>

        {/* Search Section */}
        <form onSubmit={handleSearch} className="flex items-center gap-4 mb-6">
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            className="border rounded px-4 py-2"
          >
            <option value="#">Select Options</option>
            <option value="all">Get All Operators</option>
            <option value="operatorId">Search by Operator ID</option>
            <option value="network">Search by Network</option>
          </select>

          {searchType !== 'all' && searchType !== '#' && (
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={`Enter ${searchType === 'operatorId' ? 'Operator ID' : 'Network'}`}
              className="border rounded px-4 py-2"
            />
          )}

          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            Search
          </button>
        </form>

        {operators.length !== 0 && (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-100 text-gray-700">
                  <th className="py-2 px-4 border-b text-left">OperatorId</th>
                  <th className="py-2 px-4 border-b text-left">Name</th>
                  <th className="py-2 px-4 border-b text-left">Network</th>
                  <th className="py-2 px-4 border-b text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {operators.map((operator) => (
                  <tr key={operator.id} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">{operator.operatorId}</td>
                    <td className="py-2 px-4 border-b">{operator.name}</td>
                    <td className="py-2 px-4 border-b">{operator.network}</td>
                    <td className="py-2 px-4 border-b text-right">
                      <button
                        onClick={() => handleOperatorClick(operator.operatorId)}
                        className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Operator Details Modal */}
        {selectedOperator && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white rounded-lg p-6 w-full max-w-2xl h-auto max-h-[80vh] overflow-y-auto">
              
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-red-500 hover:text-red-600"
              >
                <FaTimes size={20} />
              </button>

              <h2 className="text-xl font-bold mb-4">Operator Details</h2>
              {isEditing ? (
                <div>
                  {/* {renderJSON(editForm)} */}
                  <JsonViewer editable={true} displayDataTypes={false}  value={editForm} />
                  <div className="flex justify-start gap-2 mt-4">
                    <button
                      onClick={handleSaveChanges}
                      className="bg-green-500 text-white py-1 px-4 rounded mr-2 hover:bg-green-600"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="bg-red-500 text-white py-1 px-4 rounded hover:bg-red-600"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="text-ellipsis">
                {/* <JsonEditor value={selectedOperator} /> */}
                  <JsonViewer 
                  editable={false} 
                  displayDataTypes={false} 
                  rootName={null} 
                  enableClipboard={false} 
                  defaultExpandDepth={1}
                  quotesOnKeys={false}
                  indentWidth={4}
                  value={selectedOperator} />
                  <div className="flex justify-start gap-2 mt-4">
                    <button
                      onClick={handleEditClick}
                      className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Operators;
