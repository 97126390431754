import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import CreateBusiness from './CreateBusiness';
import QRResolve from './QRResolve';
import BookingDetails from './BookingDetails';
import AddVehicle from './AddVehicle';
import ModelsList from './ModelList';
import Migration from './Migration';
// import ImageUploader from '../components/AddImage';
import StoreDetails from './StoreDetails';
import Operator from './Operator';
import InternalStoresSearch from './StoreSearch';
import PlacesAutocomplete from '../components/PlacesAutocomplete';

const HomePage = () => {
  return (
    <div className="flex">
      
      <Sidebar />

     {/* add ml-64 -- Kriti */}
      <div className="ml-64 flex-1 p-8">    
        <Routes>
          <Route path="create-business" element={<CreateBusiness />} />
          <Route path="qrresolve" element={<QRResolve />} />
          <Route path="booking-details" element={<BookingDetails />} />
          <Route path="add-vehicle" element={<AddVehicle />} />
          <Route path="model-list" element={<ModelsList />} />
          <Route path="migration" element={<Migration />} />
          <Route path="storeDetails" element={<StoreDetails />} />
          <Route path="storeSearch" element={<InternalStoresSearch />} />
          <Route path="operator" element={<Operator />} />
          <Route path='places' element={<PlacesAutocomplete />} />
        </Routes>
      </div>
    </div>
  );
};

export default HomePage;
