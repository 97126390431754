import React, { useState } from 'react';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../AuthService';

export function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [signupMethod, setSignupMethod] = useState('email');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://api.chargepe.io/v1/internaluser/signup', {
        email: email,
        password,
        phone: {
          countryCode: '91',
          phoneNumber: phone
        },
        type: 'INTERNAL_USER',
        mode: 'EMAIL_PASSWORD'
      });
      
      if (response.data?.token) {
        AuthService.setToken(JSON.stringify(response.data));
        console.log('Signup successful:', response.data);
        navigate('/home');
      }
    } catch (error) {
      console.error('Signup failed:', error);
    }
  };

  const googleSignup = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        //  send this token to backend for verification
        console.log('Google signup success:', codeResponse);
        //  make an API call to backend with the Google response
        // const response = await axios.post('your-backend-endpoint', { googleToken: codeResponse.access_token });
        // AuthService.setToken(JSON.stringify(response.data));
        navigate('/home');
      } catch (error) {
        console.error('Google signup processing failed:', error);
      }
    },
    onError: (error) => console.log('Google signup failed:', error)
  });

  return (
    <form onSubmit={handleSignup} className="space-y-4">
      <div className="flex space-x-4 mb-4">
        <button
          type="button"
          className={`flex-1 py-2 px-4 rounded ${signupMethod === 'email' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setSignupMethod('email')}
        >
          Email
        </button>
        <button
          type="button"
          className={`flex-1 py-2 px-4 rounded ${signupMethod === 'phone' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setSignupMethod('phone')}
        >
          Phone
        </button>
      </div>

      {signupMethod === 'email' ? (
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      ) : (
        <input
          type="tel"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      )}

      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full px-3 py-2 border rounded"
        required
      />

      <button
        type="submit"
        className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
      >
        Sign Up
      </button>

      <button
        type="button"
        onClick={() => googleSignup()}
        className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
      >
        Sign Up with Google
      </button>
    </form>
  );
}

export default Signup;
