import React, { useState } from 'react';
import { fetchStoreDetails } from '../api'; 

const StoreDetails = () => {
  const [storeId, setStoreId] = useState('');
  const [storeDetails, setStoreDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFetchStoreDetails = async () => {
    setLoading(true);
    setError(null);
    setStoreDetails(null);
    try {
      const response = await fetchStoreDetails(storeId);
      setStoreDetails(response);
    } catch (err) {
      setError(err.message || 'Error fetching store details');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-6 max-w-md mx-auto bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">Store Details</h2>
      <input
        type="text"
        placeholder="Enter Store ID"
        value={storeId}
        onChange={(e) => setStoreId(e.target.value)}
        className="mb-4 p-2 w-full border border-gray-300 rounded-md"
      />
      <button
        onClick={handleFetchStoreDetails}
        className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
        disabled={!storeId || loading}
      >
        {loading ? 'Loading...' : 'Fetch Store Details'}
      </button>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {storeDetails && (
        <div className="mt-6 w-full">
          <h3 className="text-lg font-bold">{storeDetails.name}</h3>
          <p className="text-sm text-gray-600 mb-4">{storeDetails.description}</p>
          <div className="flex flex-col space-y-2">
            <p><strong>City:</strong> {storeDetails.city}</p>
            <p><strong>State:</strong> {storeDetails.state}</p>
            <p><strong>Operator ID:</strong> {storeDetails.operatorId}</p>
            <p><strong>Size:</strong> {storeDetails.size}</p>
            <p><strong>Latitude:</strong> {storeDetails.latitude}</p>
            <p><strong>Longitude:</strong> {storeDetails.longitude}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreDetails;
