import React, { useState } from 'react';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../AuthService';

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [loginMethod, setLoginMethod] = useState('email');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.chargepe.io/v1/internaluser/auth/login', {
        mode: 'EMAIL_PASSWORD',
        email: email,
        password,
        userType: 'INTERNAL_USER'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'CF-Access-Client-Id': 'dbd0cd3b802178ebfea3730685743602.access',
          'CF-Access-Client-Secret': '391969e13719345282c3ad8738a44266b698e60ce27ce8f950ae5050e0ebc459'
        }
      });

      if (response.data?.token) {
        AuthService.setToken(JSON.stringify(response.data));
        console.log('Login successful:', response.data);
        navigate('/home');
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        // send this token to  backend for verification
        console.log('Google login success:', codeResponse);
        // make an API call to  backend with the Google response
        // const response = await axios.post('your-backend-endpoint', { googleToken: codeResponse.access_token });
        // AuthService.setToken(JSON.stringify(response.data));
        navigate('/home');
      } catch (error) {
        console.error('Google login processing failed:', error);
      }
    },
    onError: (error) => console.log('Google login failed:', error)
  });

  return (
    <form onSubmit={handleLogin} className="space-y-4">
      <div className="flex space-x-4 mb-4">
        <button
          type="button"
          className={`flex-1 py-2 px-4 rounded ${loginMethod === 'email' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setLoginMethod('email')}
        >
          Email
        </button>
        <button
          type="button"
          className={`flex-1 py-2 px-4 rounded ${loginMethod === 'phone' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setLoginMethod('phone')}
        >
          Phone
        </button>
      </div>

      {loginMethod === 'email' ? (
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      ) : (
        <input
          type="tel"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      )}

      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full px-3 py-2 border rounded"
        required
      />

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      >
        Login
      </button>

      <button
        type="button"
        onClick={() => googleLogin()}
        className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
      >
        Login with Google
      </button>
    </form>
  );
}

export default Login;