import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa"; 
import { JsonViewer } from '@textea/json-viewer'

const LocationAutocomplete = () => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchLocationSuggestions = async (query) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.chargepe.io/v1/internaluser/google/place/autocomplete?prompt=${query}`,
        {
          headers: {
            Authorization: "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJVU1I4MTU5MDgyMTAxIiwiaWF0IjoxNzMwNDM4MjA5LCJpc3MiOiJjaGFyZ2VwZSIsInVzZXJEZXRhaWxzIjp7InVzZXJJZCI6IlVTUjgxNTkwODIxMDEiLCJuZXdVc2VyIjpmYWxzZSwiY3JlYXRlZEF0IjpudWxsLCJncm91cE5hbWUiOm51bGwsImdyb3VwVmFsdWUiOm51bGwsImF1dGhvcml0aWVzIjpbIkIyQyJdfSwiZXhwIjoxNzM5MDc4MjA5fQ.H92iGsJ0hrizx_AgBvCG1NEJ2kdK3Q3wruZbPeNFer3Imhrvs0czw8u8nXBRoqhFFfQVjKXuVAVGJDJ2aiXhjg", 
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSuggestions(data.predictions);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.length > 2) {
      fetchLocationSuggestions(query);
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handleSelectSuggestion = (suggestion) => {
    setSelectedLocation(suggestion);
    setQuery(suggestion.description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLocation(null);
  };

  return (
    <div className="max-w-md mx-auto p-4 font-sans">
      <div className="flex items-center bg-gray-200 rounded-full p-2 shadow-md transition-all">
        <FaSearch className="text-gray-600 text-xl mr-4 ml-3" />
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={(e) => e.target.placeholder = ""}
          onBlur={(e) => e.target.placeholder = "Search location"}
          placeholder="Search location"
          className="w-full bg-transparent outline-none text-lg placeholder-gray-500"
        />
      </div>

      {loading && <p className="mt-2 text-center text-gray-500">Loading...</p>}

      <ul className="mt-3">
        {suggestions.map((suggestion) => (
          <li
            key={suggestion.placeId}
            onClick={() => handleSelectSuggestion(suggestion)}
            className="p-3 border-b border-gray-300 cursor-pointer hover:bg-gray-100"
          >
            {suggestion.description}
          </li>
        ))}
      </ul>

      {/* Modal for JSON View */}
      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-"
          onClick={closeModal}
        >
          <div
            className="relative bg-white rounded-lg p-6 w-full max-w-2xl h-auto max-h-[80vh] overflow-y-auto "
            onClick={(e) => e.stopPropagation()} 
          >
            <h3 className="text-lg font-semibold mb-3">Location Details (JSON View)</h3>
            
              {/* {JSON.stringify(selectedLocation, null, 2)} */}
              <JsonViewer className="mx-h-[700px] overflow-hidden"
                  editable={false} 
                  displayDataTypes={false} 
                  rootName={null} 
                  enableClipboard={false} 
                  defaultExpandDepth={1}
                  quotesOnKeys={false}
                  indentWidth={4}
                  value={selectedLocation} />
            
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationAutocomplete;
