/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import JsonEditor from './JsonEditor';  
import { approveImage, rejectImage, updateStoreData } from '../api';

const StoreDetailsEditor = ({ data, onSave }) => {
  const [storeData, setStoreData] = useState(data);
  const [imageStatus, setImageStatus] = useState({});  

  useEffect(() => {
    setStoreData(data);
  }, [data]);

  const handleImageApproval = async (imageId) => {
    try {
      await approveImage(storeData.store.storeId, imageId);
      setImageStatus((prevStatus) => ({
        ...prevStatus,
        [imageId]: 'approve',
      }));
      alert('Image approved successfully!');
    } catch (error) {
      console.error('Error approving image:', error);
      alert('Error approving the image. Check console for details.');
    }
  };

  const handleImageRejection = async (imageId) => {
    try {
      await rejectImage(storeData.store.storeId, imageId);
      setImageStatus((prevStatus) => ({
        ...prevStatus,
        [imageId]: 'reject',
      }));
    } catch (error) {
      console.error('Error rejecting image:', error);
      alert('Error rejecting the image.');
    }
  };

  const handleSave = async (updatedData) => {
    try {
      await updateStoreData(storeData.store.storeId, updatedData);
      onSave(updatedData);
    } catch (error) {
      console.error('Error updating store data:', error);
      
    }
  };

   return (
    <div className="p-4 max-w-4xl mx-auto bg-white rounded-lg shadow-md border">
      <h2 className="text-2xl font-semibold mb-4">Store Details Editor</h2>

      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">Store Contexts</h3>
        <JsonEditor
          data={storeData.store}
          onSave={(updatedStore) =>
            handleSave({
              ...storeData,
              store: updatedStore,
            })
          }
        />
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">Charging Stations</h3>
        <JsonEditor
          data={storeData.chargingStations}
          onSave={(updatedChargingStations) =>
            handleSave({
              ...storeData,
              chargingStations: updatedChargingStations,
            })
          }
        />
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">Primary Store Image</h3>
        {storeData.primaryStoreImage ? (
          <div className="flex flex-col items-center mb-2">
            <img
              src={storeData.primaryStoreImage.s3Url}
              alt="Primary Store"
              className="w-32 h-32 object-cover rounded border"
            />
            <div className="flex gap-2 mt-2">
              <button
                onClick={() =>
                  handleImageApproval(storeData.primaryStoreImage.imageId, 'approve')
                }
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
              >
                Approve
              </button>
              <button
                onClick={() =>
                  handleImageRejection(storeData.primaryStoreImage.imageId, 'reject')
                }
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
              >
                Reject
              </button>
            </div>
          </div>
        ) : (
          <p>No primary image available.</p>
        )}
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">Secondary Store Images</h3>
        {storeData.secondaryStoreImages && storeData.secondaryStoreImages.length > 0 ? (
          <div className="flex flex-wrap gap-4">
            {storeData.secondaryStoreImages.map((image, index) => (
              <div key={index} className="flex flex-col items-center">
                <img
                  src={image.s3Url}
                  alt={`Secondary ${index}`}
                  className="w-32 h-32 object-cover rounded border"
                />
                <div className="flex gap-2 mt-2">
                  <button
                    onClick={() => handleImageApproval(image.imageId, 'approve')}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleImageRejection(image.imageId, 'reject')}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No secondary images available.</p>
        )}
      </div>
    </div>
  );
};

StoreDetailsEditor.propTypes = {
  data: PropTypes.object.isRequired,  
  onSave: PropTypes.func.isRequired,  
};

export default StoreDetailsEditor;
