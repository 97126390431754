import React, { useState, useEffect } from 'react';

const JsonEditor = ({ data, onSave }) => {
  const [jsonData, setJsonData] = useState(JSON.stringify(data, null, 2));

  useEffect(() => {
    setJsonData(JSON.stringify(data, null, 2));
  }, [data]);

  const handleJsonChange = (event) => {
    setJsonData(event.target.value);
  };

  const handleSave = () => {
    try {
      const updatedData = JSON.parse(jsonData);
      onSave(updatedData);
    } catch (error) {
      alert('Invalid JSON format');
    }
  };

  return (
    <div className="mb-4">
      <textarea
        value={jsonData}
        onChange={handleJsonChange}
        rows={10}
        className="w-full p-2 border rounded-md"
      />
      <button
        onClick={handleSave}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
      >
        Save
      </button>
    </div>
  );
};

export default JsonEditor;
