import React, { useEffect, useState } from 'react';
import { fetchModels } from '../api'; 

const ModelsList = () => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 

  useEffect(() => {
    const getModels = async () => {
      try {
        const data = await fetchModels();
        setModels(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getModels();
  }, []);

  if (loading) {
    return <div className="text-center p-4">Loading models...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-100">
      <h1 className="text-3xl font-bold mb-6 text-center">Models List</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {models.length > 0 ? (
          models.map((model) => (
            <div key={model.modelId} className="border border-gray-300 p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white">
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">{model.name}</h2>
              <p><strong>Vendor:</strong> {model.vendor}</p>
              <p><strong>SKU:</strong> {model.sku}</p>
              <p><strong>Wheel Size:</strong> {model.wheelsize}</p>
              <p><strong>Expected Range (kms):</strong> {model.expectedRangeKms}</p>

              <div className="mt-4">
                <h3 className="font-bold text-lg">Battery Specifications:</h3>
                <p><strong>Type:</strong> {model.batterySpecs.type}</p>
                <p><strong>Capacity (kWh):</strong> {model.batterySpecs.capacityKWH}</p>

                <h4 className="font-semibold mt-2">Charge Time Estimates:</h4>
                {model.batterySpecs.chargeTimeEstimates.length > 0 ? (
                  model.batterySpecs.chargeTimeEstimates.map((estimate, index) => (
                    <div key={index} className="border border-gray-200 p-2 rounded-md mb-2 bg-gray-50">
                      <p><strong>From Percentage:</strong> {estimate.fromPercentage}%</p>
                      <p><strong>To Percentage:</strong> {estimate.toPercentage}%</p>
                      <p><strong>Power (kWh):</strong> {estimate.withPowerKWH}</p>
                      <p><strong>Time (minutes):</strong> {estimate.timeInMinutes}</p>
                    </div>
                  ))
                ) : (
                  <p>No charge time estimates available.</p>
                )}

                <p><strong>Fast Charge Allowed:</strong> {model.batterySpecs.fastChargeAllowed ? 'Yes' : 'No'}</p>
                <p><strong>Fast Charge Recommended:</strong> {model.batterySpecs.fastChargeRecommended ? 'Yes' : 'No'}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600">No models available.</p>
        )}
      </div>
    </div>
  );
};

export default ModelsList;
