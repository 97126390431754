import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  const [openTab, setOpenTab] = useState(null);

  const toggleTab = (tab) => {
    setOpenTab(openTab === tab ? null : tab);
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? 'bg-gray-500 border-l-4 border-blue-500' : '';
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-64 bg-gray-800 text-white">
      <div className="p-4 text-2xl font-bold">Internal App</div>
      <nav className="mt-6">
        <ul>
          <li className="p-4 hover:bg-gray-700">
            <div onClick={() => toggleTab('model')} className="cursor-pointer">
              Model
            </div>
            {openTab === 'model' && (
              <ul className="pl-4">
                <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/add-vehicle')}`}>
                  <Link to="/add-vehicle">Add Model</Link>
                </li>
                <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/model-list')}`}>
                  <Link to="/model-list">Model List</Link>
                </li>
              </ul>
            )}
          </li>
          <li className="p-4 hover:bg-gray-700">
            <div onClick={() => toggleTab('store')} className="cursor-pointer">
              Store
            </div>
            {openTab === 'store' && (
              <ul className="pl-4">
                <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/storeDetails')}`}>
                  <Link to="/storeDetails">Store Details</Link>
                </li>
                <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/storesearch')}`}>
                  <Link to="/storesearch">Store Search</Link>
                </li>
              </ul>
            )}
          </li>
          
          <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/home')}`}>
            <Link to="/home">Home</Link>
          </li>
          <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/create-business')}`}>
            <Link to="/create-business">Create Business</Link>
          </li>
          <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/qrresolve')}`}>
            <Link to="/qrresolve">QR Resolve</Link>
          </li>
          <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/booking-details')}`}>
            <Link to="/booking-details">Booking Details</Link>
          </li>
          <li className={`p-4 hover:bg-gray-700 ${getActiveClass('/operator')}`}>
            <Link to="/operator">Operator List</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
